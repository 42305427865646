import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createCachedSelector } from 're-reselect'
import { AppThunk, RootState } from '@/store'

const initialState: SliceState = {
  cached: {},
  loading: false,
  error: null,
}

const pages = createSlice({
  name: 'pages',
  initialState: initialState,
  reducers: {
    requestPage(state) {
      state.loading = true
    },
    successPage(state, { payload }: PayloadAction<{ key: string; data?: PageData }>) {
      const { key, data } = payload
      const { cached } = state
      if (data) {
        cached[key] = { data, error: null }
      }
      state.loading = false
    },
    failurePage(state, { payload }: PayloadAction<{ key: string; err: Error }>) {
      const { key, err } = payload
      const { cached } = state
      cached[key] = { data: null, error: err }

      state.loading = false
    },
  },
})

export const fetchPage = ({ slug }: { slug: string }): AppThunk => async (
  dispatch,
  getState,
  { api },
) => {
  const key = slug

  try {
    dispatch(requestPage())
    const { cached } = getState().pages

    if (cached[slug]) {
      dispatch(successPage({ key }))
      return
    }

    const params = { inlineSlug: slug }
    const response: ResponseSuccess | ResponseError = await api.pages({ params })

    if (isResponseSuccess(response)) {
      dispatch(successPage({ key, data: response.data }))
    } else {
      dispatch(failurePage({ key, err: response }))
    }
  } catch (err) {
    dispatch(failurePage({ key, err: { statusCode: 500 } }))
  }
}

export const getPageBySlug = createCachedSelector(
  (state: RootState) => state.pages,
  (_state: RootState, slug: string) => slug,
  (pages, slug) => pages.cached[slug],
)((_state, slug) => slug)

export const { requestPage, successPage, failurePage } = pages.actions

export default pages.reducer

// <-- Types --> //

type SliceState = {
  cached: { [key: string]: { data: PageData | null; error: Error | null } }
  loading: boolean
  error: Error | null
}

interface Error {
  statusCode: number
}

export interface PageData {
  header: string
  text: string
}

type ResponseSuccess = {
  result: 'success'
  data: PageData
}

interface ResponseError extends Error {
  result: 'error'
}

function isResponseSuccess(response: ResponseSuccess | ResponseError): response is ResponseSuccess {
  return response.result === 'success'
}
