import axios, { AxiosRequestConfig } from 'axios'
import qs from 'qs'

axios.defaults.paramsSerializer = params => qs.stringify(params)

export default class Api {
  baseUrl: string
  masterSignUp
  coordsByZip
  closestByZip
  mastersByZip
  contactMaster
  projects
  pages
  masterPhoneClick

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
    this.masterSignUp = this._postFormData('masters/sign-up')
    this.masterPhoneClick = this._post('masters/add-phone-click')
    this.coordsByZip = this._get('zip')
    this.closestByZip = this._get('zip/closest')
    this.mastersByZip = this._get('zip/masters')
    this.contactMaster = this._post('order/create')
    this.projects = this._get('projects')
    this.pages = this._get('slug')
  }

  private fullUrl(url: string, inlineSlug?: string) {
    return inlineSlug ? `${this.baseUrl}/${url}/${inlineSlug}` : `${this.baseUrl}/${url}`
  }

  _get(url: string) {
    return (query: AxiosRequestConfig = {}) => {
      const { cancelToken = axios.CancelToken.source().token, params = {} } = query
      const { inlineSlug, ...restParams } = params

      return axios
        .get(this.fullUrl(url, inlineSlug), {
          cancelToken,
          params: restParams,
        })
        .then(response => response.data)
        .catch(error => {
          const config = error.response.config
          const { method, url, params } = config
          return {
            result: 'error',
            statusCode: error.response.status,
            config: { method, url, params },
            data: error.response.data,
          }
        })
    }
  }

  _post(url: string) {
    return (query: AxiosRequestConfig = {}) => {
      const { cancelToken = axios.CancelToken.source().token, data } = query

      return axios
        .post(this.fullUrl(url), data, {
          cancelToken,
        })
        .then(response => response.data)
        .catch(error => ({
          result: 'error',
          statusCode: error.response.status,
          ...error.response.data,
        }))
    }
  }

  _postFormData(url: string) {
    return (data: Record<any, any>) => {
      const fd = this._toFormData(data)
      console.log('fd: ', fd)

      return axios
        .post(this.fullUrl(url), fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => response.data)
        .catch(error => ({
          result: 'error',
          statusCode: error.response.status,
          ...error.response.data,
        }))
    }
  }

  _toFormData(obj: Record<any, any>, form?: FormData, namespace?: string) {
    let fd = form || new FormData()
    let formKey

    for (let property in obj) {
      if (obj.hasOwnProperty(property) && obj[property] !== null) {
        if (namespace) {
          formKey = namespace + '[' + property + ']'
        } else {
          formKey = property
        }

        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString())
        } else if (
          typeof obj[property] === 'object' &&
          obj[property] !== null &&
          !(obj[property] instanceof File)
        ) {
          this._toFormData(obj[property], fd, formKey)
        } else {
          fd.append(formKey, obj[property] === null ? '' : obj[property])
        }
      }
    }

    return fd
  }
}
